import React, { useState, useEffect } from 'react';
import { navigate } from 'gatsby';
import { EntryNumberModal, TicketNumberModal } from './ticket_modals';
import isApp from '../../utils/isApp';
import sendTicketEntryMetrics from '../../utils/sendTicketEntryMetrics';

export const EntryForm = ({ promotion_id, promotion, appUrl, createfunc, ticket, entry, actions }) => {
    const formUrl = appUrl + '/entry.php';

    const initialState = {
        ticket1_1_4: '',
        ticket1_1_3: '',
        ticket1_1_2: '',
        ticket1_1_1: '',
        entry_method: 'manual',
    };

    const [formState, setFormState] = useState(initialState);

    const [disabled, setDisabled] = useState(false);

    useEffect(() => {
        clearIfSuccess();
    }, [ticket]);

    // fire the submission
    const handleSubmit = async event => {
        setDisabled(true);
        event.preventDefault();

        sendTicketEntryMetrics(isApp(), false, ticket.success);

        await createfunc(formState);

        setDisabled(false);
    };

    // if the form field changes, we will change the value in the store and
    // also check if the next field must be focused
    const handleChange = function(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        setFormState({ ...formState, [name]: value.toUpperCase() });

        autotab(target, target.dataset.next);
    };

    // if the form submit was a success, we clear the form
    const clearIfSuccess = async () => {
        if (ticket.success) {
            // UNCOMMENT FOR GAME MODAL on URL
            //await actions.gameActions.getGame(entry.last_entry);

            setFormState(initialState);
            navigate('/ticket_checker/post_check', {
                state: { returnURL: '/ticket_checker/manual', entry_method: formState.entry_method },
            });
        }
    };

    // rehash of old autotab functionality from PA
    const autotab = (current, nex) => {
        var next = document.getElementById(nex);

        if (!!nex && !!current && current.getAttribute && current.value.length === parseInt(current.getAttribute('maxlength'))) {
            next.focus();
        }
    };

    return (
        <div id='form-target' className='container'>
            <form
                name='ticket_entry'
                id='ticket_entry'
                action={formUrl}
                disabled={disabled}
                method='post'
                encType='application/x-www-form-urlencoded'
                onSubmit={handleSubmit}>
                <div className='form-cluster'>
                    <label htmlFor='ticket1_1_4'>
                        <span className='float-left default-font'>Entry Number</span>
                        <span className='float-right default-font'>
                            <EntryNumberModal />
                        </span>
                    </label>
                    <br />
                    <input
                        type='text'
                        name='ticket1_1_4'
                        id='ticket1_1_4'
                        data-next='ticket1_1_1'
                        value={formState.ticket1_1_4}
                        onChange={handleChange}
                        size='12'
                        maxLength='12'
                        className='ticket1_1_1 form-control'
                        placeholder='xxxx'
                    />
                </div>
                <div className='form-cluster'>
                    <label htmlFor='ticket1_1_1'>
                        <span className='float-left default-font'>Ticket Number</span>
                        <span className='float-right default-font'>
                            <TicketNumberModal />
                        </span>
                    </label>
                    <div className='form-inline'>
                        <input
                            type='text'
                            name='ticket1_1_1'
                            id='ticket1_1_1'
                            data-next='ticket1_1_2'
                            value={formState.ticket1_1_1}
                            onChange={handleChange}
                            size='4'
                            maxLength='4'
                            className='ticket1_1_2 form-control'
                            placeholder='xxxx'
                        />
                        <input
                            type='text'
                            name='ticket1_1_2'
                            id='ticket1_1_2'
                            data-next='ticket1_1_3'
                            value={formState.ticket1_1_2}
                            onChange={handleChange}
                            size='7'
                            maxLength='7'
                            className='ticket1_1_3 form-control'
                            placeholder='xxxxxx'
                        />
                        <input
                            type='text'
                            name='ticket1_1_3'
                            id='ticket1_1_3'
                            value={formState.ticket1_1_3}
                            onChange={handleChange}
                            size='3'
                            maxLength='3'
                            className='ticket1_1_4 form-control'
                            placeholder='xxxx'
                        />
                    </div>
                </div>

                <div className='form-cluster'>
                    <input id='entry-submit' type='submit' name='submit' value='Check if Winner' className='btn btn-primary' />
                </div>
            </form>
        </div>
    );
};

export default EntryForm;
