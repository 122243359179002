const USER_MESSAGES = {
    PASSWORD_RULES_1: 'PASSWORD_RULES_1',
    PASSWORD_RULES_1_CONDENSED: 'PASSWORD_RULES_1_CONDENSED',
    PASSWORD_RULES_2: 'PASSWORD_RULES_2',
};
const userMessages = {
    [USER_MESSAGES.PASSWORD_RULES_1]:
        'Please keep in mind that your password must be at least 10 characters long and contain three of the following:',
    [USER_MESSAGES.PASSWORD_RULES_1_CONDENSED]:
        'password must be at least 10 characters long and contain three of the following:',
    [USER_MESSAGES.PASSWORD_RULES_2]:
        'uppercase letter, lowercase letter, a number, and a special character',
};

const userMessage = id => {
    if (!userMessages.hasOwnProperty(id)) {
        console.warn(`Unknown message [${id}] encountered`);
    }
    return userMessages[id] || id;
};

export { USER_MESSAGES, userMessage };
