const getQueryParam = param => {
    if (typeof window !== 'undefined') {
        const searchValues = window.location.search.split('&');
        if (searchValues.length) {
            // remove the question mark fromthe start ofthe first element
            searchValues[0] = searchValues[0].substr(1);
        }
        const queryObject = searchValues.reduce((queryObj, pair) => {
            const [key, value] = pair.split('=');
            queryObj[key] = value;
            return queryObj;
        }, {});
        return queryObject[param];
    }
};

export default getQueryParam;
