import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';

import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import ticket_checker_entry_number from '../../global/cms/ticket_checker_entry_number.jpg';
import ticket_checker_ticket_number from '../../global/cms/ticket_checker_ticket_number.jpg';

import '../../components/ticket_checker/help_modal.scss';
import { getHTML } from '../../utils/cms_copy_provider';

const EntryNumberModal = () => {
    return (
        <>
            <ModalWindow linkText='See Example' title='Entry Number'>
                <div className='modal-body'>
                    <img
                        src={ticket_checker_entry_number}
                        alt='This ticket shows where you can find the Entry Number on the front of the ticket.'
                        className='img-responsive center-block ticket-img'
                    />
                </div>
                <div className='modal-footer'>
                    <p className='text-center'>{getHTML('ticket_checker_entry_number_description')}</p>
                </div>
            </ModalWindow>
        </>
    );
};

const TicketNumberModal = () => {
    return (
        <>
            <ModalWindow linkText='See Example' title='Ticket Number'>
                <div className='modal-body'>
                    <img
                        src={ticket_checker_ticket_number}
                        alt='This ticket shows where the Ticket Number is located on the back of the ticket.'
                        className='img-responsive center-block ticket-img'
                    />
                </div>
                <div className='modal-footer'>
                    <p className='text-center'>{getHTML('ticket_checker_ticket_number_description')}</p>
                </div>
            </ModalWindow>
        </>
    );
};

const ModalWindow = props => {
    const [state, setState] = useState({ show: false });

    const handleClose = () => {
        setState({ show: false });
    };

    const handleShow = () => {
        setState({ show: true });
    };

    const handleKeyShow = event => {
        if (event.key === 'Enter') {
            setState({ show: true });
        }
    };

    const handleKeyClose = event => {
        if (event.key === 'Enter') {
            setState({ show: false });
        }
    };

    return (
        <>
            <a role='button' className='pull-right example-link' tabIndex='0' onKeyDown={handleKeyShow} onClick={handleShow}>
                &#9432; {props.linkText}
            </a>

            <Modal dialogClassName='scbz-modal carousel-modal' show={state.show} onHide={handleClose}>
                <Modal.Header>
                    <a className='modal-close-element' title='Close' tabIndex='0' role='button' onKeyDown={handleKeyClose} onClick={handleClose}>
                        <FontAwesomeIcon icon={faTimesCircle} size='3x' />
                    </a>
                </Modal.Header>
                <Modal.Body>{props.children}</Modal.Body>
            </Modal>
        </>
    );
};

export { EntryNumberModal, TicketNumberModal };
