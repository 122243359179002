import React, { Component } from 'react';

import InnerLayout from './inner_layout';

class Layout extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        // Update the document title using the browser API
        this.props.actions.configActions.getConfig();
        this.props.actions.userActions.getPlayerData();
    }

    render() {
        return (
            <>
                <InnerLayout {...this.props}>{this.props.children}</InnerLayout>
            </>
        );
    }
}

export default Layout;
