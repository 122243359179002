import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'gatsby';
import promotion_config from '../../../promotion_config';
import ScratchEntryForm from '../../components/ticket_checker/entry_form_scratch';
import { mapStateToProps, mapDispatchToProps } from '../../Store';
import Error from '../../components/enter/error';
import Layout from '../../components/layouts/Layout/app_index';
import SEO from '../../components/seo';

import '../../components/ticket_checker/style.scss';
import { getHTML, getText } from '../../utils/cms_copy_provider';
import { isBrowser } from '../../utils';

const ManualEntry = ({ user, config, notification, entry, checkTicket, game, actions }) => {
    const manualFormType = isBrowser() ? window.history.state?.manualType : null;
    const loggedIn = (usr) => {
        if (usr.loggedIn) {
            return (
                <div className='text-center'>
                    <p className='welcome text-center'>
                        Welcome
                        <span className='user-name'>{usr.player ? usr.player.name : null}</span>
                    </p>
                </div>
            );
        } else {
            return null;
        }
    };

    return (
        <Layout user={user} config={config} notification={notification} actions={actions}>
            <SEO title='Ticket Checker' />
            <div className='ticket_checker'>
                <div className='container-fluid primary-container'>
                    <div className='vip-stripe'>
                        <Link to='/ticket_checker' className='w-100 vip-link'>
                            <span>{getText('ticket_checker_title')}</span>
                            <span className='fa fa-chevron-left float-right' aria-hidden='true'></span>
                        </Link>
                    </div>
                    <div className='row'>
                        <div className='col'>
                            <div className='scbz-content-area text-center'>
                                {loggedIn(user)}
                                <div className='row'>
                                    <span className='ml-4 mb-0 directive'>{`Enter ${manualFormType} Numbers Manually`}</span>
                                </div>
                                <div className='ticket-alert'>
                                    <Error ticket={checkTicket} actions={actions} />
                                </div>
                                <div className='entry-form text-center'>
                                    <ScratchEntryForm
                                        promotion_id={promotion_config.promotion_id}
                                        promotion={promotion_config.promotion}
                                        appUrl={promotion_config.appUrl}
                                        ticket={checkTicket}
                                        entry={entry}
                                        game={game}
                                        actions={actions}
                                        createfunc={actions.checkTicketActions.checkTicket}
                                    />
                                    <p className='text-left disclaimer time-disclaimer'>{getHTML('ticket_checker_time_disclaimer')}</p>
                                    <p className='text-left disclaimer'>{getHTML('ticket_checker_second_disclaimer')}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(ManualEntry);
